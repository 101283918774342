<template>
  <div ref="cesium" class="h-100 data_wrap">
    <div class="data_tool p-10">
      <Button type="info" size="small" class="tool-button" @click="setNaviga"
        >指北针</Button
      >
      <Button
        type="info"
        size="small"
        class="tool-button m-t-10"
        @click="rotate"
        >绕线</Button
      >
      <Button
        type="info"
        size="small"
        class="tool-button m-t-10"
        @click="birdView"
        >鸟瞰</Button
      >
      <Button
        type="info"
        size="small"
        class="tool-button m-t-10"
        @click="lookDown"
        >俯视</Button
      >
      <Button
        type="info"
        size="small"
        class="tool-button m-t-10"
        @click="lookAt"
        >平视</Button
      >
      <Button
        type="info"
        size="small"
        class="tool-button m-t-10"
        @click="renderGPX"
        >GPX</Button
      >
      <!-- <Button type="info" size="small" class="tool-button m-t-10">全景</Button> -->
    </div>
    <transition name="fade">
      <div :class="['map_tool p-10', flag ? '' : 'left_close']">
        <div class="detail_info">
          <div class="title"><span class="line"></span>研学路线详情</div>
          <div class="detail_name ellipsis f-20 m-t-10">
            {{ detail.lineName }}
          </div>
          <Vgroup :labelWidth="90" class="transport">
            <Vcell label="位置：" class="bg_color">
              {{ `${province} - ${city} - ${county}` }}
            </Vcell>
            <Vcell
              label="是否公开："
              :value="detail.published ? '公开' : '非公开' || '未知'"
            />
            <Vcell
              label="适合季节："
              :value="detail.lineSeason || '未知'"
              class="bg_color"
            />
            <Vcell label="所属机构：" :value="base_org" />
            <Vcell label="所属学段：" class="bg_color">
              <span v-if="!detail.applicablePhases">未知</span>
              <template v-else>
                <Tag
                  :color="color[index]"
                  v-for="(item, index) in detail.applicablePhases"
                  :key="index"
                  class="m-r-5"
                  >{{ item }}</Tag
                >
              </template>
            </Vcell>
            <Vcell label="所属学科：">
              <span
                v-for="(item, index) in detail.subjects"
                :key="index"
                class="m-r-5"
                >{{ item || "未知" }}</span
              >
            </Vcell>
            <Vcell
              label="采集时间："
              :value="detail.createTime"
              class="bg_color"
            />
            <Vcell label="采集人：" :value="detail.ownerName || '未知'" />
            <Vcell
              label="简介："
              :value="detail.lineInfo || '暂无'"
              class="bg_color"
            />
          </Vgroup>
          <div class="detail_name f-16 m-t-10">研学路线图片</div>
          <div class="p-10 p-l-20" v-if="!detail.lineImg">暂无图片</div>
          <Row
            type="flex"
            justify="start"
            align="middle"
            :gutter="10"
            class="w-100"
            v-else
          >
            <Col
              span="12"
              v-for="(item, index) in detail.lineImg"
              :key="index"
              class="w-48 m-t-10"
            >
              <img :src="item" class="point_img" />
            </Col>
          </Row>
          <div class="detail_name f-16 m-t-10">研学路线视频</div>
          <div class="p-10 p-l-20" v-if="!detail.lineVideo">暂无视频</div>
          <Row
            type="flex"
            justify="start"
            align="middle"
            :gutter="10"
            class="w-100 p-l-20"
            v-else
          >
            <Col
              span="12"
              v-for="(item, index) in detail.lineVideo"
              :key="index"
              class="w-48 m-t-10"
            >
              <video class="point_video" controls>
                <source :src="renderUrl(item)" />
              </video>
            </Col>
          </Row>
          <div class="detail_name f-16 m-t-10">研学路线课程</div>
          <div class="p-10 p-l-20" v-if="!detail.lineCourse">暂无课程</div>
          <Row
            type="flex"
            justify="start"
            align="middle"
            :gutter="10"
            class="w-100 p-l-20"
            v-else
          >
            <Col
              span="3"
              v-for="(item, index) in detail.lineCourse"
              :key="index"
              class="w-48 m-t-10"
            >
              <img src="/static/images/model/course_img.png" class="c-h" @click="openEpub(item)" />
            </Col>
          </Row>
        </div>
        <div class="map_left_close">
          <Icon
            size="40"
            class="map_left_close_icon"
            :type="flag ? 'md-arrow-round-back' : 'md-arrow-round-forward'"
            @click="flag = !flag"
          />
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { CesiumMap } from "@/libs";
import DrawTool from "@/libs/mapByCesium/DrawTool.js";
import { getLineById } from "@/libs/api/trace";
export default {
  data() {
    return {
      flag: true,
      cesium: null,
      tool: null,
      detail: {},
      province: null,
      city: null,
      county: null,
      base_org: null,
      color: {
        0: "rgb(57, 204, 196)",
        1: "rgb(79, 181, 255)",
        2: "rgb(255, 161, 89)",
        3: "rgb(78, 138, 255)",
        4: "#7232dd",
        5: "rgb(57, 204, 196)",
      },
      color2: {
        0: "magenta",
        1: "volcano",
        2: "orange",
        3: "cyan",
        4: "geekblue",
        5: "purple",
      },
    };
  },
  methods: {
    
    openEpub(epub_url) {
      console.log(epub_url)
      let epubId = null
      if(epub_url.includes('/minio-netdisk/file/')) {
        epubId = epub_url.split('/')[epub_url.split('/').length - 1]
      }
      window.open(`${process.env.VUE_APP_BASE_RESEARCH}/researchCourseView/${epubId}`)
    },
    renderGPX() {
      if(this.tool.rotateBool) {
        this.tool.stopRotate()
      }
      //   添加gpx文件
      if (this.detail.lineGpx) {
        let gpxUrl = null;
        if (
          this.detail.lineGpx.includes(
            "/minio-netdisk/file/"
          )
        ) {
          gpxUrl = this.detail.lineGpx.split('/')[this.detail.lineGpx.split('/').length - 1];
        } else if (this.detail.lineGpx.includes(".gpx")) {
          gpxUrl = this.detail.lineGpx.replace(".gpx", "");
        } else gpxUrl = this.detail.lineGpx;

        this.tool.loadGPX(
          `${process.env.VUE_APP_BASE_API}/minio-netdisk/file/${gpxUrl}`
        );
      }
    },
    renderUrl(url) {
      if (url.includes("/minio-netdisk/file/")) {
        return url;
      } else {
        return `${process.env.VUE_APP_BASE_API}/minio-netdisk/file/${url}`;
      }
    },
    // 罗盘
    setNaviga() {
      this.cesium.setNavigation();
    },
    // 绕点
    rotate() {
      if (!this.tool.rotateBool) this.tool.rotateCamera(this.detail);
      else this.tool.stopRotate();
    },
    // 鸟瞰 90度
    birdView() {
      this.tool.birdEyeView(this.detail, 90);
    },
    // 俯视 45度默认值
    lookDown() {
      this.tool.birdEyeView(this.detail);
    },
    // 平视 90度
    lookAt() {
      this.tool.birdEyeView(this.detail, 5);
    },
    getData() {
      getLineById({ id: this.$route.query.id }).then(({ code, data }) => {
        if (code == "HA0200") {
          this.detail = Object.assign({}, this.detail, {
            ...data,
            id: "0",
            name: data.lineName,
            lng: data.lineCenter.lon,
            lat: data.lineCenter.lat,
            ele: 1000,
            width: 30,
            height: 30,
            image: "line.svg",
          });

          // this.tool.addPoint(this.detail)
          //   添加gpx文件
          if (this.detail.lineGpx) {
            let gpxUrl = null;
            if (
              this.detail.lineGpx.includes(
                "/minio-netdisk/file/"
              )
            ) {
              gpxUrl = this.detail.lineGpx.split('/')[this.detail.lineGpx.split('/').length - 1];
            } else if (this.detail.lineGpx.includes(".gpx")) {
              gpxUrl = this.detail.lineGpx.replace(".gpx", "");
            } else gpxUrl = this.detail.lineGpx;

            this.tool.loadGPX(
              `${process.env.VUE_APP_BASE_API}/minio-netdisk/file/${gpxUrl}`
            );
          }
          //   this.tool.pointCamera(lng, lat, 1000)
          this.province = this.detail.lineAdd[1];
          this.city = this.detail.lineAdd[2];
          this.county = this.detail.lineAdd[3];
          this.base_org = this.detail.lineOrg.base;
        }
      });
    },
  },

  async mounted() {
    // 加载cesium
    const cesium = await new CesiumMap(this.$refs.cesium, {
      homeButton: false,
      sceneModePicker: false,
      isCredit: true,
      isAnimation: true,
      isTimeline: true,
    });
    this.cesium = cesium;
    this.tool = await new DrawTool(cesium);
    this.getData();
  },
  beforeDestroy() {
    this.cesium.removeCesium();
  },
};
</script>

<style lang="less" scoped>
.data_wrap {
  position: relative;
  width: 100%;
  height: 100%;
  .data_tool {
    position: absolute;
    top: calc(50% - 100px);
    z-index: 999;
    color: #fff;
    background: rgba(0, 0, 0, 0.2);
    right: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
  }
  .detail_info {
    height: 100%;
    overflow-y: scroll;
  }
  .map_tool {
    z-index: 999;
    position: absolute;
    bottom: 20px;
    color: #fff;
    left: 20px;
    height: calc(~"100% - 110px");
    background: rgba(0, 0, 0, 0.4);
    box-shadow: 0px 0px 5px 5px rgba(0, 0, 0, 0.2);
    width: 500px;
    transition: all 3s;
    // border: solid 1px rgb(38, 170, 228);
    border-radius: 0 20px 20px 0;
    // overflow: hidden;
  }
  .title {
    width: 100%;
    height: 30px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 16px;

    .line {
      background-color: #18ffff;
      display: inline-block;
      border-left: 3px solid #18ffff;
      border-right: 3px solid #18ffff;
      border-radius: 5px;
      height: 30px;
      width: 6px;
      margin-right: 10px;
    }
  }
  .detail_name {
    padding-left: 20px;
    font-weight: 600;
  }
  .bg_color {
    background: rgba(24, 123, 206, 0.2);
  }
  .point_img {
    width: 100%;
    height: 140px;
  }
  .point_video {
    width: 100%;
    height: 150px;
  }

  .map_left_close {
    position: absolute;
    top: calc(50% - 60px);
    left: 500px;
    width: 60px;
    height: 60px;
    border: solid 1px #fff;
    border-radius: 50%;
    text-align: center;
    line-height: 70px;
    z-index: 999;
  }
  .map_left_close_icon:hover {
    color: #efefef;
  }
  .left_close {
    left: -500px;
  }
}
</style>
